<i18n lang="yaml">
pt:
  addField: "Adicionar novo campo"
  addFieldCTA: 'Adicionar campo'
  errors:
    target_sheet:
      confidential_sheet_required: 'É necessário selecionar uma base de dados confidencial'
  infos:
    DownloadFile: 'A URL do arquivo precisa ter acesso público. O limite de tamanho de arquivo é de 25MB.'
  removeStep: 'Remover ação'
  removeStepConfirmation: 'Tem certeza que deseja remover esta ação?'
  renameStep: 'Renomear Ação'
  runtimeOverride: 'Permitir alteração ao executar'
  selectTargetViewIntro:
    page: 'Disponível apenas para páginas com permissão de edição habilitada.'
    permission: 'A mensagem não será enviada se o usuário que receber a pesquisa não possuir permissão de edição à página de destino escolhida.'
    note: 'Anexos e Imagens ainda não são suportados via Slack.'
  step: 'Ação'
  stopWorkflowWarning: 'Essa ação irá parar a execução da automação e de todas as ações subsequentes.'

en:
  addField: "Add new field"
  addFieldCTA: 'Add field'
  errors:
    target_sheet:
      confidential_sheet_required: 'You must select a confidential sheet'
  infos:
    DownloadFile: 'The file URL must be publicly accessible.{br}{br}The file size limit is 25MB.'
  removeStep: 'Remove action'
  removeStepConfirmation: 'Are you sure you want to remove this action?'
  renameStep: 'Rename Action'
  runtimeOverride: 'Allow changes on execution'
  selectTargetViewIntro:
    page: 'Available only for pages with edit permission enabled.'
    permission: 'The message will not be sent if the user who receives the survey does not have edit permission to the chosen target page.'
    note: 'Attachments and Images are not yet supported via Slack.'
  step: 'Action'
  stopWorkflowWarning: 'This step will stop the workflow execution and skip all succeeding steps.'
</i18n>
<template>
  <transition
    name="scale-transition"
    appear
  >
    <div class="step-editor">
      <div
        class="step-editor__wrapper"
        :class="{
          'step-editor__wrapper--is-group': isLoopStep(step) || isConditionalStep(step),
          'step-editor__wrapper--is-highlighted': isHoveringEditorWrapper,
        }"
        @mouseenter="isHoveringEditorWrapper = isLoopStep(step)"
        @mouseleave="isHoveringEditorWrapper = false"
      >
        <deck-accordion
          :model-value="stepsVisibility[step.id] || false"
          :label-icon-prepend="`fa-${step.displayIcon} fa-${step.displayIconKind}`"
          :label="stepName"
          class="step-editor__step"
          @mouseover:toggle-button="isHoveringStep = true"
          @mouseleave:toggle-button="isHoveringStep = false"
          @update:model-value="setStepVisibility({ stepId: step.id, visible: $event })"
        >
          <template #header-label>
            <deck-trimmer
              :line-clamp="isRenaming ? 0 : 2"
              :class="{ 'd-inline-flex align-center w-stretch': isRenaming }"
              tag="span"
            >
              <span class="mr-1 d-inline-flex">{{ step.displayIndex }}.</span><EditableText
                v-test-id="'stepNameInput'"
                :model-value="step.displayName"
                :editable="isRenaming"
                :class="{ 'd-inline-flex': isRenaming }"
                @update:model-value="onChangeStepName"
                @update:editable="isRenaming = $event"
              />
            </deck-trimmer>
          </template>

          <template #append-header-label>
            <transition
              :name="isRenaming ? null : 'slide-x-reverse-transition'"
            >
              <div
                v-if="(isHoveringStep && !isRenaming) || isRemoving"
                class="d-inline-flex gap-1 ml-1"
              >
                <deck-button
                  is-ready
                  icon="pen-field"
                  kind="ghost"
                  icon-kind="regular"
                  color="controls"
                  :text="t('renameStep')"
                  @click.stop="renameStep"
                />
                <deck-button
                  is-ready
                  icon="trash"
                  icon-kind="regular"
                  kind="ghost"
                  color="controls"
                  :text="t('removeStep')"
                  :loading="isRemoving"
                  :disabled="isRemoving"
                  @click.stop="removeStep"
                />
              </div>
            </transition>
          </template>

          <div class="mt-2">
            <template v-if="schemaWithMapping.target_view && step.action_type === 'RequestSlackInput'">
              <deck-alert class="mb-2">
                {{ t('selectTargetViewIntro.page') }}<br>
                {{ t('selectTargetViewIntro.permission') }}
              </deck-alert>
              <deck-alert class="mb-2">
                {{ t('selectTargetViewIntro.note') }}
              </deck-alert>
            </template>

            <template v-if="step.action_type === 'StopWorkflow'">
              <deck-alert class="mb-2">
                {{ t('stopWorkflowWarning') }}
              </deck-alert>
            </template>

            <ActionDynamicReference
              v-for="(schemaItem, key) in schemaWithMapping"
              :key="key"
              :schema-item="schemaItem"
              :step="step"
              :deletable="schemaItem.builder_hide"
              :allow-setup-override="schemaItem.allow_override"
              :allow-setup-override-disabled="schemaItem.allow_override_disabled"
              class="mb-4"
              @key-removed="removeField"
            />
          </div>

          <div
            v-if="hiddenMappingOptions.length > 0"
            class="pt-2"
          >
            <deck-button
              v-show="!displayAutocomplete"
              :loading="reloadingCurrentWorkflow"
              :disabled="reloadingCurrentWorkflow"
              kind="secondary"
              size="small"
              :text="t('addFieldCTA')"
              @click="showAutocomplete"
            />
            <deck-select
              v-show="displayAutocomplete"
              ref="autocompleteMapping"
              :items="hiddenMappingOptions"
              :label="t('addField')"
              :model-value="selectedFieldToAdd"
              outlined
              dense
              hide-details="auto"
              @update:model-value="addField"
              @focusout="displayAutocomplete = false"
            />
          </div>
          <deck-alert
            v-if="step.action_type === 'DownloadFile'"
            :text="t('infos.DownloadFile')"
          />
        </deck-accordion>

        <StepCreator
          v-if="!isConditionalStep(step)"
          :step="step"
          :parent-id="isLoopStep(step) ? step.id : parentId || null"
          :is-first-loop-step="isLoopStep(step)"
          :is-root="isRoot"
          :conditional-type="conditionalType"
        />

        <template v-if="isLoopStep(step)">
          <StepEditor
            v-for="(loopStep) in step.sorted_steps"
            :key="loopStep.id"
            :step="loopStep"
            :parent-id="step.id"
          />
        </template>

        <StepEditorConditional
          v-if="isConditionalStep(step)"
          :step="step"
          :parent-id="step.id"
        />
      </div>

      <StepCreator
        v-if="isLoopStep(step) || isConditionalStep(step)"
        :parent-id="parentId || null"
        :step="step"
        :is-root="isRoot"
        :conditional-type="conditionalType"
      />
    </div>
  </transition>
</template>
<script>
import { mapActions, mapMutations, mapState } from '~/assets/javascript/modules/vuex';
import { dataMixin } from '~/mixins/dataMixin';
import { fieldMixin } from '~/mixins/fieldMixin';

export default {
  name: 'StepEditor',
  components: {

    ActionDynamicReference: defineAsyncComponent(() => import('~/components/workflows/ActionDynamicReference')),
    EditableText: defineAsyncComponent(() => import('~/components/studio/EditableText')),
    StepCreator: defineAsyncComponent(() => import('~/components/studio/workflows/StepCreator')),
    StepEditorConditional: defineAsyncComponent(() => import('~/components/studio/workflows/StepEditorConditional')),
    DeckTrimmer: defineAsyncComponent(() => import('~/deck/trimmer')),
  },
  mixins: [dataMixin, fieldMixin],
  props: {
    step: { type: Object, required: true },
    isRoot: { type: Boolean, default: false },
    conditionalType: { type: String, default: null },
    parentId: { type: String, default: null },
  },
  setup() {
    return {
      t: useI18n().t,
      ...mapState('workflows', ['stepsVisibility', 'reloadingCurrentWorkflow']),
      ...mapActions('workflows', ['updateStep', 'removeStepMapping', 'removeWorkflowStep']),
      ...mapMutations('dialog', ['openDialog']),
      ...mapMutations('workflows', ['setStepVisibility', 'updateLocalStepMapping']),
    };
  },
  data() {
    return {
      displayAutocomplete: false,
      isHoveringEditorWrapper: false,
      isHoveringStep: false,
      isRenaming: false,
      isRemoving: false,
      selectedFieldToAdd: null,
    };
  },
  computed: {
    stepName() {
      return `${this.step.displayIndex}. ${this.step.displayName} `;
    },
    schemaWithMapping() {
      const { step } = this;

      if (!step) return {};

      const schema = {};

      Object.entries(step.action_schema).forEach(([key, schemaItem]) => {
        if (schemaItem.builder_hide && !step.mapping[key]) return;

        if (schemaItem.display_if) {
          const { key, accessor, value } = schemaItem.display_if;

          const mappingItem = this.getMappingItem(key);

          if (accessor === 'value' && mappingItem.value !== value) return;
          if (accessor === 'type' && mappingItem.type !== value) return;
        }

        schema[key] = {
          ...schemaItem,
          mapping: step.mapping[key] || {},
          key,
        };
      });

      return schema;
    },
    hiddenMappingOptions() {
      return Object.entries(this.step.action_schema)
        .reduce((acc, [key, schemaItem]) => {
          if (schemaItem.builder_hide && !this.schemaWithMapping[key]) {
            acc.push({
              value: key,
              text: schemaItem.display_name[this.$i18n.locale],
            });
          }

          return acc;
        }, []);
    },
  },
  methods: {
    async onChangeStepName(name) {
      try {
        await this.updateStep({
          stepId: this.step.id,
          payload: { name },
        });
      } catch {
        // just ignore, the store already handles the error
      } finally {
        this.isRenaming = false;
      }
    },
    async removeStep() {
      this.openDialog({
        component: 'DialogConfirmAction',
        attrs: {
          warning: true,
          text: this.t('removeStepConfirmation'),
        },
        on: {
          onConfirm: async () => {
            try {
              this.isRemoving = true;

              await this.removeWorkflowStep(this.step.id);

              window.analytics.track('removeWorkflowStep', {});

              this.$notifier.showMessage({
                content: this.$t('global.saveSuccess'),
                color: 'success',
              });
            } catch (error) {
              this.$errorRescue(this, error, 'removeStep');
            } finally {
              this.isRemoving = false;
            }
          },
        },
      });
    },
    renameStep() {
      this.isRenaming = true;
    },
    async showAutocomplete() {
      this.displayAutocomplete = true;

      // wait the autocomplete to be shown
      await nextTick();

      this.$refs.autocompleteMapping.openAutocomplete();
    },
    async addField(key) {
      // This is used to reset the autocomplete value after selecting an option
      this.selectedFieldToAdd = key;
      await nextTick();

      if (!key) return;
      window.analytics.track('stepEditorChange', { change: 'addField', key });

      this.updateLocalStepMapping({
        workflowId: this.step.workflow_id,
        stepId: this.step.id,
        key,
        value: {},
      });

      this.selectedFieldToAdd = null;
    },
    removeField(key) {
      window.analytics.track('stepEditorChange', {
        change: 'removeField',
        key,
      });

      this.removeStepMapping({ stepId: this.step.id, keys: [key] });
    },
    isLoopStep(step) {
      return ['Loop', 'SplitPdf', 'AssignConfidentialField'].includes(step.action_type);
    },
    isConditionalStep(step) {
      return step.action_type === 'Conditional';
    },
    getMappingItem(key) {
      const itemFromMapping = this.step.mapping[key];
      if (itemFromMapping) return itemFromMapping;
      const fallbackItem = {};

      if (this.step.action_schema[key].default !== undefined) {
        fallbackItem.value = this.step.action_schema[key].default;
      }

      return fallbackItem;
    },
  },
};
</script>
<style lang="scss">
.miniLabel label.v-label {
  font-size: 12px;
}

.step-editor__step {
  background-color: var(--z-theme-background);
}

// TODO: this wrapper is also used inside StepEditorConditional. We should
// extract it to a separate component.
.step-editor__wrapper--is-group {
  padding-inline: 24px;
  margin-inline: -4px;
  border: 1px solid var(--z-color-text);
  border-style: dashed;
  border-radius: 8px;
  margin-top: 24px;
  background-color: var(--z-main-background-accent-color);
}

.step-editor__wrapper--is-group > .step-editor__step:first-child {
  margin-inline: -20px;
  margin-top: -24px;
}

// God bless `:has` selector.
.step-editor__wrapper--is-group:has(.step-editor__wrapper--is-group:hover) {
  background-color: var(--z-main-background-accent-color);
}

.step-editor__wrapper--is-group:hover {
  background-color: var(--z-theme-background-secondary);
}

// JS fallback for browsers that don't support `:has` selector. Won't highlight
// when hovering inner steps of a loop. I can live with that :)
.step-editor__wrapper--is-highlighted {
  background-color: var(--z-theme-background-secondary);
}
</style>
